import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from 'styled-components'
import Layout from '../components/Layout'
import { Container, defaultTheme } from "../styles"
import { motion } from 'framer-motion'
import PageBanner from '../components/PageBanner'
import SEO from '../components/SEO'

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        seoTitle
        metaDesc
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`

const transition = {
  duration: 0.25,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const PageTemplate = ({ pageContext, data: { mdx: project }}) => {
  return(
    <Layout>
      <SEO
        title={project.frontmatter.seoTitle}
        metaDesc={project.frontmatter.metaDesc}
      />
      <PageBanner title={project.frontmatter.title} subtitle={`California Sex Abuse Lawyer`} />
      <Container>
        <PageBody
          initial={{
            opacity: 0,
            y: 10,
            transition
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.75, ...transition, duration: 0.75}
          }}
        >

            <MDXRenderer>
              {project.body}
            </MDXRenderer>
   
        </PageBody>
      </Container>
    </Layout>
  )
}

export default PageTemplate

const PageBody = styled(motion.div)`
  padding: 48px 0;

  @media(min-width: 900px) {
    padding: 96px 0;
  }

  p {
    margin: 0 0 20px;
    max-width: 820px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.618;

    a {
      font-weight: 700;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          border-bottom: 1px solid ${defaultTheme.darkBlue};
        }
      }
    }
  }

  h2, h3 {
    margin: 0 0 24px;
  }

  ul {
    margin: 24px 0;
    max-width: 820px;
    padding-left: 25px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.618;
    list-style: disc;

    li {
        margin-bottom: 12px;
    }
  }
`;